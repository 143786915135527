import { render, staticRenderFns } from "./Floorplan.vue?vue&type=template&id=3b12b9fb&scoped=true&"
import script from "./Floorplan.vue?vue&type=script&lang=js&"
export * from "./Floorplan.vue?vue&type=script&lang=js&"
import style0 from "./Floorplan.vue?vue&type=style&index=0&id=3b12b9fb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b12b9fb",
  null
  
)

export default component.exports