<template>
  <div>
    <div class="outer-wrapper">
      <div class="container-fluid">
          <div class="row justify-content-center mob-row">
            <div class="col-sm-12 col-lg-8">
              <div class="col-md-12 logo-header">
                <div class="brand-logo-mob text-left">
                  <img src="@/assets/images/mp-logo.png" width="100px" height="100px" alt="" class="img-fluid">
                </div>
              </div>
              <div class="plan-holder">
                <div class="mx-auto text-center floor-map-section w-100">
                  <FloorPlanSVG />
                </div>
                <div class="floor-progress-bar">
                  <ProgressBar :steps="formSteps" :activeStepNo="2"/>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-lg-4 right-container">
            <Floors/>
            </div>
          </div>
          <div class="col-12 powered-by powered-by-mob">Powered by <span class="powered-by-ppistol"> PropertyPistol&nbsp;Technologies</span></div>

      </div>

    </div>
  </div>

 
</template>
<script>
import {FormSteps} from '@/assets/scripts/utility.js';
export default {
  components: {
    FloorPlanSVG:()=>import("@/components/FloorPlanSVG.vue"),
    Typologies:()=>import("@/components/Home/Typologies.vue"),
    Floors: ()=>import('@/components/Home/Floors.vue'),
    ProgressBar:() => import('@/components/ProgressBar.vue')
  },
  data(){
    return {
      formSteps:FormSteps
    }
  },
  mounted() {
    this.$store.dispatch('setLoading',false);
  },
  computed:{
    isMobile(){
      return window && window.innerWidth <= 991;
    }
  },
  methods : {
    // typologyPlanSrc() {
    //   return require(`@/assets/images/floorplan.jpg`);
    // },
  }
};
</script>
<style scoped>
.logo-header {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.outer-wrapper {
  margin: 0;
  background: #f6f6f6;

}

.compass-img{
  margin-left: auto;
  margin-top: auto;
}
.plan-holder {
  border-radius: 10px;
  padding: 0;
  background: transparent;
}


@media (min-width:992px){
  .plan-holder{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
    height: 100vh;
  }
  .floor-progress-bar{
    width: 100%;
  }
}
.plan-holder h3 {
  font-weight: 600;
  font-size: 18px;
  color: #082D57;
  border-bottom: 2px solid #F5F5F5;
  padding: 20px;
}

.right-container{
  background: #ffffff;
  padding: 0px 30px;
  position: relative;
  z-index: 2;
}

.side-menu {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.brand-logo {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  margin-bottom: 20px;
}

h3 {
  font-weight: 600;
  font-size: 20px;
  color: #000000;
  padding: 20px;
  margin: revert;
}

.side-menu.typology {
  height: 100%;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
  border-radius: 10px 0px 0px 10px;
  position: relative;
}

.side-menu.typology .list-group {
  flex-direction: column;
}

.side-menu.typology .list-group-item {
  padding: 5px 0;
}

.custom-control {
  margin-right: 0;
}

.custom-control-label {
  padding: 15px 15px 15px 40px;
}

.custom-control-label .divider {
  width: 5px;
  height: 100%;
  border-radius: 0px 4px 4px 0px;
}

.typology .custom-control-label::before,
.typology .custom-control-label::after {
  top: 17px;
  left: 15px;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
  top: 20px;
  left: 18px;
}

.side-action {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  gap: 15px;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  background: #fff;
  border-radius: 10px;
}

.side-action .btn-primary {
  background: #082D57;
  border-radius: 4px;
  padding: 15px;
}

.side-action p {
  font-weight: 400;
  font-size: 9px;
  color: #082D57;
  opacity: 0.5;
  text-align: center;
}
.form-wrapper {
  padding: 0 20px;
}
.form-wrapper  label {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: -0.01em;
  color: #082D57;
  opacity: 0.5;
}
.form-wrapper  select {
  background: url(../assets/images/caret-icon.svg) no-repeat right .75rem center #FFFFFF;
  border: 1px solid #E7EBF0;
  border-radius: 3px;
  padding: 10px;
  appearance: none;
  height: auto;
  font-weight: 600;
  font-size: 15px;
  color: #082D57;
}
.sub-head {
  font-weight: 600;
  font-size: 15px;
  color: #082D57;
  display: block;
}
.card-foot {
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 20px;
  }
  .card-footer-head span{
    letter-spacing: -0.01em;
    color: #082D57;
    opacity: 0.5;
    font-weight: 500;
    font-size: 12px;
  }
  h5 {
    font-weight: 600;
    font-size: 15px;
    color: #082D57;
  }
.link-btn {
  position: absolute;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 50%;
}


  .info-wrapper h2{
    font-size: 12px;
    font-weight: bold;
    color: #000;
    margin-bottom: 10px;
  }
     .info-wrapper .page-heading {
    font-size: 12px;
    font-weight: bold;
    color: #000;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 4px;
  }

  
  .info-wrapper p {
    font-size: 13px;
    color: #000000;
    text-transform: uppercase;
    font-weight: 600;
    opacity: 0.5;
    padding-left: 0px;
    padding-bottom: 0px;
    margin-bottom: 12px;
  }
.info-wrapper .align-data {
    font-size: 13px;
    color: #000000;
    line-height: 16px;
    opacity: 0.5;
    text-transform: none;
    padding-left: 0px;
    padding-top: 0;
    margin-bottom: 0;
 }
.compass-img-mob{
  display: none;
}

.powered-by{
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #00000080;
  text-align: center;
  padding-top: 20px;
  margin-top: auto;
}
.powered-by .powered-by-ppistol{
  color: #000000;
}


.info-wrapper.mob-show,
.mob-show{
  display: none;
}
.powered-by-mob{
  display: none;
}

@media(min-width: 992px){
  .right-container{
    overflow-y: auto;
    height: 100vh;
  }
}

@media (max-width:991px){

  .mob-row .col-sm-12{
    position: static;
  }

  .row.mob-show{
    display: flex;
  }
  .right-container{
    margin-top: 20px;
    position: static;
    padding: 0;
  }
  .logo-header{
    position: absolute;
    top: 0;
    left: 0;
    background: #FFFFFF;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
  }

  .plan-holder{
    margin-top: 80px;
    padding-top: 15px;
    height: 60%;
  }

  .floor-map-section{
    height: 100%;
    overflow-x: unset;
  }
  .side-menu{
    min-height: unset;
  }

  .logo-header h3{
    margin: auto;
  }

  .brand-logo-mob{
    position: absolute;
  }

  .powered-by{
    display: none;
  }

  .powered-by.powered-by-mob{
    display: block;
    position: static;
    margin: auto;
    text-align: center;
    transform: none;
    margin-top: 40px;
    margin-bottom: 160px;
  }
  .compass-img{
    display: none;
  }
  .compass-img-mob{
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  }
  .floor-progress-bar{
    padding-bottom: 4px;
    margin-top: 50px;
  }
}
</style>